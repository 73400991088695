import React, { useEffect, useState, useContext } from 'react';

import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import styles from './Footer.module.css';
import { SiteSettingsContext } from '../../contexts/SiteSettingsProvider';
import CookiePolicyMessage from './CookiePolicyMessage/CookiePolicyMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';

const Footer = () => {
  const settings = useContext(SiteSettingsContext);
  const [ContactByTelephoneText, setContactByTelephoneText] = useState();
  const [ContactTelephone, setContactTelephone] = useState();
  const [ContactBySocialNetworksText, setContactBySocialNetworksText] = useState();

  const [col1Title, setCol1Title] = useState();
  const [col2Title, setCol2Title] = useState();

  const [cookiePolicyMessage, setCookiePolicyMessage] = useState();

  useEffect(() => {

    if (settings) {
      setContactByTelephoneText(settings.FooterTexts.ContactByTelephoneText);
      setContactTelephone(settings.FooterTexts.ContactTelephone);
      setContactBySocialNetworksText(settings.FooterTexts.ContactBySocialNetworksText);

      setCol1Title(settings.FooterTexts.FooterColumn1Title);
      setCol2Title(settings.FooterTexts.FooterColumn2Title);

      setCookiePolicyMessage(settings.FooterTexts.CookiePolicyMessage);
    }
  }, [settings]);

  let nav1Items;
 
  if (settings) {
    let nav1 = settings.BotNav1;
 
    if (nav1) {
      nav1Items = nav1.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }
 
  }


  if (settings) {
    return (

      <>
        <div className={styles.Footer + " tw-py-5 tw-mt-5 tw-bg-primary  tw-text-white"}>

          <div className="mid-footer  tw-py-8">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-3">
                <div className="tw-pr-5">
                  <h2>{col2Title}</h2>



                  <div className="tw-flex tw-items-top tw-mb-3">
                    {settings.SiteSettings.ContactTelephone && (
                      <>
                        <FontAwesomeIcon icon={faPhone} className="tw-text-lg tw-mr-2 tw-mt-1" />
                        <p>Telephone: {settings.SiteSettings.ContactTelephone}</p>
                      </>
                    )}
                  </div>
                  <div className="tw-flex tw-items-top tw-mb-3">
                    {settings.SiteSettings.ContactEmail && (
                      <>
                        <FontAwesomeIcon icon={faEnvelope} className="tw-text-lg tw-mr-2 tw-mt-1" />
                        <p>Email: {settings.SiteSettings.ContactEmail}</p>
                      </>
                    )}
                  </div>
                  <div className="tw-flex tw-items-top tw-mb-3">
                    {settings.SiteSettings.Address && (
                      <>
                        <FontAwesomeIcon icon={faLocationDot} className="tw-text-lg tw-mr-2 tw-mt-1" />
                        <p>   {settings.SiteSettings.Address.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                      </>
                    )}
                  </div>

                </div>
                <div className="">
                  <h2>{col1Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav1Items}
                  </ul>
                </div>

                <div className="">
                  {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn4Text }}></span>}

                </div>

              </div>
            </div>
          </div>


        </div>
        {(cookiePolicyMessage && cookiePolicyMessage !== "" ) && 
          <CookiePolicyMessage text={cookiePolicyMessage} />
        }

        

      </>
    );
  }

};



export default Footer;
