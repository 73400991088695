import { useEffect, useState, useContext } from 'react';
import { SiteSettingsContext, CurrencyContext } from 'contexts/SiteSettingsProvider';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import SearchForm from 'components/SearchForm/SearchForm';
import './SearchWidget.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import ReactPlayer from 'react-player';

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"

// install Swiper's Autoplay module
SwiperCore.use([Autoplay]);

const isVideo = (url) => {
    return url.includes('.mp4') || url.includes('.webm') || url.includes('.ogg');
};

const SearchWidget = ({ data, pageId, keyID, settings }) => {
    const siteSettings = useContext(SiteSettingsContext);
    const video = data.Images?.find((image) => isVideo(image.Url));
    let cleanUrl = video?.Url?.split("?")[0];
 
    

    if (video) {
        return (
            <div className='tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center SearchWidget-bg tw-min-h-[70vh] search-video-widget'>
                <div className='tw-absolute tw-top-0 tw-w-full tw-h-full'>
                    <ReactPlayer
                        url={cleanUrl}
                        playing={true}
                        loop={true}
                        muted
                        width='100%'
                        height='100%'
                        style={{ objectFit: 'fill' }}
                    />
                </div>
                <div className="tw-absolute tw-top-0 tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                    {data.Title && <h2 className="tw-text-center tw-text-white tw-mb-0">{data.Title}</h2>}
                    {data.SubTitle && <h3 className="tw-text-center tw-text-white">{data.SubTitle}</h3>}
                    {(pageId && keyID) && <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings} />}
                </div>
            </div>
        ); 
    }   

    if (data.Images && data.Images.length === 1){
        return (
            <div className={`tw-flex tw-flex-col tw-items-center tw-justify-center SearchWidget-bg tw-max-h-[70vh]`}
                style={{ backgroundImage: `url(${data.Images[0].Url})`, backgroundSize: 'cover', minHeight: `${(data.Images[0].Height <2000) ?  data.Images[0].Height : 700}px`, maxHeight: '70vh' }} >
                {data.Title && <h2 className="tw-text-center tw-text-white tw-mb-0">{data.Title}</h2>}
                {data.SubTitle && <h3 className="tw-text-center tw-text-white">{data.SubTitle}</h3>}
                {(pageId && keyID) && <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings}  />}
            </div>
        )
    }

    if (data.Images && data.Images.length > 1) {
        return (
            <div className='tw-min-h-[200px] tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center SearchWidget-bg tw-max-h-[70vh]'>
                <Swiper
                    autoplay={{ delay: 4500, disableOnInteraction: true }}
                    loop={true}
                    navigation
                    pagination={{ clickable: true }}
                    className="tw-w-full tw-h-full">
                    {data.Images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                className='tw-object-cover tw-w-full  '
                                src={image.Url}
                                alt={image.Name}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="tw-absolute tw-top-0 tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                    {data.Title && <h2 className="tw-text-center tw-text-white tw-mb-0">{data.Title}</h2>}
                    {data.SubTitle && <h3 className="tw-text-center tw-text-white">{data.SubTitle}</h3>}
                    {(pageId && keyID) && <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings} />}
                </div>
            </div>
        );
    }


    if (!data.Images || data.Images.length === 0){
        return (
            <WidgetWrapper data={data}>
                {data.Title && <h2 className='tw-mb-0'>{data.Title}</h2>}
                {data.SubTitle && <h3>{data.SubTitle}</h3>}
                {(pageId && keyID) && <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} settings={settings}  />}
            </WidgetWrapper>
        )
    }


};

export default SearchWidget;