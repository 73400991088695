import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import Location from './Location/Location';
import LocationMobile from './Location/LocationMobile';
import DatePicker from './DatePicker/DatePicker';
import DatePickerMobile from './DatePicker/DatePickerMobile';
import Guests from './Guests/Guests';
import GuestsMobile from './Guests/GuestsMobile';
import Filters from './Filters/Filters';
import FiltersMobile from './Filters/FiltersMobile';
import SkeletonSearchForm from '../SkeletonSearchForm/SkeletonSearchForm';
import './SearchForm.css';
import axios from 'axios';
import useGetUrlParams from 'utils/useGetUrlParams';
import moment from 'moment';
import { SiteSettingsContext, CurrencyContext, SortContext } from 'contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
 
 
const SearchForm = ({ pageID, keyID,  transferAllData, view, liftUpScrollImageState, isLoading, loadError, settings}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  if (view === undefined) {
    view = "horizontal";
  }
 
  const { width } = useWindowSize();


  function reducer(state, action) {
    switch (action.type) {
      case 'changelocation':
        return {
          ...state,
          data: { ...state.data, Location: action.payload.location || '' },
          completed: { ...state.completed, changelocation: true }
        };
      case 'changeadults':
        return {
          ...state,
          data: { ...state.data, Adults: action.payload?.Adults || 1 },
          completed: { ...state.completed, changeadults: true }
        };
      case 'changechildren':
        return {
          ...state,
          data: { ...state.data, Children: action.payload?.children || 0 },
          completed: { ...state.completed, changechildren: true }
        };
      case 'changeinfants':
        return {
          ...state,
          data: { ...state.data, Infants: action.payload?.Infants || 0 },
          completed: { ...state.completed, changeinfants: true }
        };
      case 'changedates':
        return {
          ...state,
          data: {
            ...state.data,
            CheckIn: action.payload.CheckIn || '',
            CheckOut: action.payload.CheckOut || ''
          },
          completed: { ...state.completed, changedates: true }
        };
      case 'changeattrs':
        return {
          ...state,
          data: { ...state.data, Attributes: action.payload.attributes || '' },
          completed: { ...state.completed, changeattrs: true }
        };
      case 'changepricemode':
        return {
          ...state,
          data: { ...state.data, PriceFilterType: action.payload.priceFilterType || 'Nightly' },
          completed: { ...state.completed, changepricemode: true }
        };
      case 'changepricerangenightly':
        return {
          ...state,
          data: { ...state.data, NightlyPriceRange: action.payload.nightlyPriceRange || '' },
          completed: { ...state.completed, changepricerangenightly: true }
        };
      case 'changepricerangeweekly':
        return {
          ...state,
          data: { ...state.data, WeeklyPriceRange: action.payload.weeklyPriceRange || '' },
          completed: { ...state.completed, changepricerangeweekly: true }
        };
      case 'changecurrency':
        return {
          ...state,
          data: { ...state.data, Currency: action.payload.currency || 'GBP' }, // Default to 'GBP' if no currency provided
          completed: { ...state.completed, changecurrency: true },
        };
      case 'changeflexiblenights':
        return {
          ...state,
          data: { ...state.data, FlexibleNights: action.payload.flexibleNights !== undefined ? action.payload.flexibleNights : false },
          completed: { ...state.completed, changeflexiblenights: true }
        };
      case 'changeSort':
        return {
          ...state,
          data: { ...state.data, Sort: action.payload.sort || 'bedrooms' },
          completed: { ...state.completed, changeSort: true }
        };
      case 'changesortdirection':
        return {
          ...state,
          data: { ...state.data, SortDirection: action.payload.sortDirection || 'asc' },
          completed: { ...state.completed, changesortdirection: true }
        };  
      default:
        throw new Error('Unhandled action type: ' + action.type);
    }
  }

   
  const initialState = {
    data: {
      clearcache: true,
      Location: '',
      Adults: 1,
      Children: 0,
      Infants: 0,
      CheckIn: '',
      CheckOut: '',
      Attributes: '',
      PriceFilterType: '',
      NightlyPriceRange: '',
      WeeklyPriceRange: '',
      Currency: '',
      FlexibleNights: '',
      Sort: '',
      SortDirection: ''
    },
    completed: {
      changelocation: false,
      changeadults: false,
      changechildren: false,
      changeinfants: false,
      changedates: false,
      changeattrs: false,
      changepricemode: false,
      changepricerangenightly: false,
      changepricerangeweekly: false,
      changecurrency: false,
      changeflexiblenights: false,
      changeSort: false,
      changesortdirection: false
    }
  };

 
  const currency = useContext(CurrencyContext);
  const { setSort, setOrder } = useContext(SortContext);
  const [currencyHasChanged, setCurrencyHasChanged] = useState(false);
  const siteSettings = useContext(SiteSettingsContext);
  const inputRef = useRef()
  const navigate = useNavigate();
  const [clearChildFiled, setClearChildFiled] = useState(0);
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const [enableDateFilter, setEnableDateFilter] = useState(false);
  const [enableFlexibleNightsFilter, setEnableFlexibleNightsFilter] = useState(false);
  const [enableGuestFilter, setEnableGuestFilter] = useState(false);
  const [enableLocationFilter, setEnableLocationFilter] = useState(false);
  const [enablePetsFilter, setEnablePetsFilter] = useState(false);
  const [enablePriceRangeSlider, setEnablePriceRangeSlider] = useState(false);
  const [priceRangeSliderSettings, setPriceRangeSliderSettings] = useState(false);
  const [enablePropertyReferenceName, setEnablePropertyReferenceName] = useState(false);
  const [flexibleNights, setFlexibleNights] = useState(false);
  const [searchFormId, setSearchFormId] = useState(false);
  const [searchInLocation, setSearchInLocation] = useState(false);
  const [searchResultsPageId, setSearchResultsPageId] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [enableImageScroll, setEnableImageScroll] = useState(''); 
  const [openSection, setOpenSection] = useState(1);
  const [defaultSortOrder, setDefaultSortOrder] = useState('');
  const [sortDirection, setSortDirection] = useState('');
 
  let location = useLocation();
 
  const [error, setError] = useState(null);

  let [isFilters, setEnabledFilters] = useState(false);
  let [locations, setLocations] = useState([]);
  let [attributes, setAttributes] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  let [pageId, setPageId] = useState();
  let [keyId, setKey] = useState();
  const [isValidForm, setIsValidForm] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
 
  useEffect(() => {
 
    const stickyDiv = document.getElementById('mobileForm');
    
    if (stickyDiv) {
    
     
      const handleScroll = () => {
        
        if (window.scrollY >= 550) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loadingForm, isValidForm]);

 
  let domain = process.env.REACT_APP_BASE_URL;
  let APIUrl = "apis/searchpage";

  useEffect(() => {
    if (enableImageScroll !== '' && window.location.pathname === 'search/') {
      liftUpScrollImageState(enableImageScroll);
    }
  }, [enableImageScroll]); 

  useEffect(() => {
    if (sortDirection !== '') {
      dispatch({ type: "changesortdirection", payload: { sortDirection: sortDirection } });
      setOrder(sortDirection);
    }
  }, [sortDirection]);

  useEffect(() => {
    if (defaultSortOrder !== '') {
      dispatch({ type: "changeSort", payload: { sort: defaultSortOrder } });

      setSort(defaultSortOrder);
    }
  }, [defaultSortOrder]);

  
 

  useEffect(() => {
    setPageId(pageID);
    setKey(keyID);
    if (settings) {
      setEnableImageScroll(settings?.EnableImageScroll);
      setEnableFlexibleNightsFilter(settings?.EnableFlexibleNightsFilter);
      setEnableGuestFilter(settings?.EnableGuestFilter);
      setEnableLocationFilter(settings?.EnableLocationFilter);
      setEnableDateFilter(settings?.EnableDateFilter);
      setEnablePriceRangeSlider(settings?.EnablePriceRangeSlider);
      setPriceRangeSliderSettings(settings?.PriceRangeSliderSettings);
      setEnablePetsFilter(settings?.EnablePetsFilter);
      setEnablePropertyReferenceName(settings?.EnablePropertyReferenceName);
      setFlexibleNights(settings?.FlexibleNights);
      setSearchFormId(settings?.SearchFormId);
      setSearchInLocation(settings?.SearchInLocation);
      setSearchResultsPageId(settings?.SearchResultsPageId);
      setEnabledFilters(settings?.EnableAttributesFilter);
      setLocations(settings?.Locations);
      setAttributes(settings?.AttibutesSettings);
      setLoadingForm(true);
      setDisableSearch(settings?.DisableSearch);
      setDefaultSortOrder(settings?.DefaultSortOrder);
      setSortDirection(settings?.DefaultSortDirection);
    } else {
      const fetchSettings = async () => {
        try {
          const response = await fetch(`${domain}${APIUrl}?pageid=${pageID}&key=${keyID}&clear=true`);
          if (response.ok) {
            const data = await response.json();
            setIsValidForm(data.Success);
            setEnableImageScroll(data.Settings?.EnableImageScroll);
            setEnableFlexibleNightsFilter(data.Settings?.EnableFlexibleNightsFilter);
            setEnableGuestFilter(data.Settings?.EnableGuestFilter);
            setEnableLocationFilter(data.Settings?.EnableLocationFilter);
            setEnableDateFilter(data.Settings?.EnableDateFilter);
            setEnablePriceRangeSlider(data.Settings?.EnablePriceRangeSlider);
            setPriceRangeSliderSettings(data.Settings?.PriceRangeSliderSettings);
            setEnablePetsFilter(data.Settings?.EnablePetsFilter);
            setEnablePropertyReferenceName(data.Settings?.EnablePropertyReferenceName);
            setFlexibleNights(data.Settings?.FlexibleNights);
            setSearchFormId(data.Settings?.SearchFormId);
            setSearchInLocation(data.Settings?.SearchInLocation);
            setSearchResultsPageId(data.Settings?.SearchResultsPageId);
            setEnabledFilters(data.Settings?.EnableAttributesFilter);
            setLocations(data.Settings?.Locations);
            setAttributes(data.Settings?.AttibutesSettings);
            setLoadingForm(true);
            setDefaultSortOrder(data.Settings?.DefaultSortOrder);
            setSortDirection(data.Settings?.DefaultSortDirection);
            window.sessionStorage.setItem("pagesettings", JSON.stringify(data));
          } else {
            setError(`HTTP error: ${response.status}`);
            console.error(`HTTP error: ${response.status}`);
            loadError(response.status);
          }
        } catch (error) {
          setError('Error fetching data');
          console.error('Error fetching data:', error);
          loadError(error);
        }
      };

      fetchSettings();
    }
  }, [pageID]);



  useEffect(() => { 
  
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const urlParams = {};
    for (const [key, value] of params) {
      urlParams[key] = value;
    }
    const isURLParams = Object.keys(urlParams).length > 0;

    //No params and we are on the search page - use initial params 
    if (!isURLParams && (location.pathname === '/search' || location.pathname === '/search/')){
      setTimeout(() => {
        transferAllData(initialState.data);
      }, 1);
    }
   
 
  }, [location]); 

 

  useEffect(() => {
    if (currency && currency[0] === '') {
      dispatch({ type: "changecurrency", payload: { currency: siteSettings.Currency } });
    }


    if (!enableGuestFilter) {

      dispatch({
        type: "changeadults",
        payload: { Adults: '' } // Encapsulating adults in an object
      });


      dispatch({
        type: "changechildren",
        payload: { children: '' } // Encapsulating children in an object
      });

      dispatch({
        type: "changeinfants",
        payload: { Infants: '' }
      });
    }


  }, []); 

  useEffect(() => {
    if (currency && currency[0] !== '') { 
      dispatch({ type: "changecurrency", payload: { currency: currency[0] } });
      console.log('currency', currency[0]);  
    }
    
    if(hasLoaded) {
      setCurrencyHasChanged(true);
    }
     
  }, [currency]); 

  useEffect(() => {
    if (currencyHasChanged && (window.location.pathname === '/search/' || window.location.pathname === '/search')) {
      startSearch();
      setCurrencyHasChanged(false);
    }
  }, [currencyHasChanged]);

 
   useEffect(() => {
    
     if (window.location.pathname === '/search/' && window.location.pathname === '/search') {
        return false;
     }
 
     if (hasLoaded && !window.location.pathname === '/search/') {
       let search = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
       if (search.Adults === '1' &&
         search.Children === "0" &&
         search.Infants === "0" &&
         search.CheckIn === "" &&
         search.CheckOut === "" &&
         search.Location === "" &&
         search.NightlyPriceRange === "" &&
         search.PriceFilterType === "" &&
         search.Currency === "" &&
         search.WeeklyPriceRange === "") {
         transferAllData(initialState.data);
         setHasLoaded(true)
       }
       return;
     }
 
     const allCompleted = Object.values(state.completed).every(Boolean);
 

    //  if (allParametersCame && hasLoaded && isCurrencySearch && (window.location.pathname === '/search' || window.location.pathname === '/search/')) {
    //     transferAllData(searchParapms);
    //     isCurrencySearch = false;
    //  }
     
 
     if (allCompleted && transferAllData && (window.location.pathname !== '/search' && window.location.pathname !== '/search/')) {
 
       transferAllData(state.data);
     }

 
     if (allCompleted && !hasLoaded && (window.location.pathname === '/search' || window.location.pathname === '/search/')) {
      
         transferAllData(state.data);
     }
  
   }, [state.completed, hasLoaded ]); 


  useEffect(() => {
    const allCompleted = Object.values(state.completed).every(Boolean);
    if (allCompleted){
      setHasLoaded(true);
    }
  }, [state.completed]);



  function transferLocation(values) {
    
    let location;

    if (Array.isArray(values) && values.length > 0) {
      // If values is a non-empty array, join the values to a string
      location = values.map(({ value }) => value).join(',');
    } else if (typeof values === "string") {
      // If values is a string, use it directly
      location = values;
       
    } else {
      // For any other case (including an empty string), default to an empty string
      location = '';
      
    }

    dispatch({
      type: "changelocation",
      payload: { location } // Use a payload object with a location property
    });
  }


  const transferAdults = (adults) => {
    // If adults is a falsy value (e.g., '', null, undefined), default to 1
    

    const numberOfAdults = adults || 1;

    dispatch({
      type: "changeadults",
      payload: { Adults: numberOfAdults } // Encapsulating adults in an object
    });
  };

  const transferChildren = (children) => {
    // If children is a falsy value (e.g., '', null, undefined), default to 0
 
    const numberOfChildren = children || 0;

    dispatch({
      type: "changechildren",
      payload: { children: numberOfChildren } // Encapsulating children in an object
    });
  };

  const transferInfants = (infants) => {
    // If infants is a falsy value (e.g., '', null, undefined), default to 0
 
    const numberOfInfants = infants || 0;
 
    dispatch({
      type: "changeinfants",
      payload: { Infants: numberOfInfants }
    });
  };
 
  const transferDates = (val) => {
    if (val[0] != null && val[1] != null) {
      let checkIn = moment(val[0]._d).format('YYYY-MM-DD');
      let checkOut = moment(val[1]._d).format('YYYY-MM-DD');

      dispatch({
        type: "changedates",
        payload: { CheckIn: checkIn, CheckOut: checkOut } // Correctly structured payload
      });
    } else {
      dispatch({
        type: "changedates",
        payload: { CheckIn: '', CheckOut: '' } // Ensure payload is structured correctly even for empty values
      });
    }
  };
 
  const transferAttrsOnLoad = (attrs) => {
    // Determine the correct string representation of attributes
    const attributes = Array.isArray(attrs) ? attrs.toString() : attrs;

    dispatch({
      type: "changeattrs",
      payload: { attributes: attributes || '' } 
    });
  };

  const transferAttrsOnChange = (attrs) => {
    dispatch({
      type: "changeattrs",
      payload: { attributes: attrs || '' }  
    });
  };

  const transferPriceMode = (mode) => {
    // If mode is falsy, default to 'Nightly'
    const priceMode = mode || 'Nightly';

    dispatch({
      type: "changepricemode",
      payload: { priceFilterType: priceMode }  
    });
  };

  const transNightRange = (range) => {
    dispatch({
      type: "changepricerangenightly",
      payload: {
        nightlyPriceRange: range || '', 
      }
    });
  };

  const transWeekRange = (range) => {
    dispatch({
      type: "changepricerangeweekly",
      payload: {
        weeklyPriceRange: range || '', 
      }
    });
  };

 
  const transferFlexibility = (val) => {
    
    // Assuming val is a boolean, there's no need to check if it's falsy. If it can be undefined, you can use `!!val` to ensure it's always a boolean.
    dispatch({
      type: "changeflexiblenights",
      payload: { flexibleNights: val }
    });
    // Assuming `setFlexibleNights` is a useState setter, this updates the local state.
    setFlexibleNights(val);
  };

  const startSearch = () => {
    toogleMobileSearchForm(false);
    if (window.location.pathname !== '/search' && window.location.pathname !== '/search/') {


      navigate("/search/" + '?' + new URLSearchParams(state.data).toString());
    } else {
      transferAllData(state.data);
    }
  }

  function startMobileSearch(e){
    if (showMobileForm && width>window.lg) {
      startSearch();
      window.scrollTo(0, 0)
    }

    var panel = document.getElementById("SortPanel");
    if (showMobileForm && width < window.lg  ) {
      if (panel) {
        var SortPanel = panel.offsetTop;
        window.scrollTo({ top: SortPanel, behavior: 'smooth' });
      }
      startSearch();
    }
 
    setShowMobileForm(!showMobileForm);
  }

  function toogleMobileSearchForm(isActive = null) {
    
    let currentState = document.body.classList.contains('mobile-search-active');

    // If isActive is provided, use it. Otherwise, toggle based on the current state.
    let newState = (isActive !== null) ? isActive : !currentState;

    if (newState) {
      document.body.classList.add('mobile-search-active');
    } else {
      document.body.classList.remove('mobile-search-active');
    }

    setShowMobileForm(!showMobileForm);
  }

  function clearAllData(){
    setClearChildFiled(prevKey => prevKey + 1);
  }



  const handleAccordionChange = (currentSection) => {
    if (currentSection === 1) {
      setOpenSection(openSection === 1 ? null : 1);
    } else if (currentSection === 2) {
      setOpenSection(openSection === 2 ? 3 : 2);
    } else if (currentSection === 3) {
      setOpenSection(openSection === 3 ? 2 : 3);
    }
  };

 
  const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home';
  
  if (disableSearch){
    return null;
  }
 
 
  if (width <= window.lg) {
    return(
      <> 
         
        {loadingForm && isValidForm ? <div id="mobileForm" style={{ position: isFixed ? 'fixed' : 'static', top: '0', backgroundColor: 'white' }} className="tw-sticky tw-top-0 tw-z-[99] mobile-search-start-button tw-ml-[-0.75rem] tw-mr-[-0.75rem] tw-mb-3 tw-bg-white tw-drop-shadow-lg" ref={inputRef} onScroll={() => { const { offsetTop } = inputRef.current }}>
          <div className="tw-p-3">
            <div className="form-control form-control-lg tw-relative tw-z-70" onClick={toogleMobileSearchForm}>
              <span>{isHomePage ? 'Start Your Search' : 'Refine my search results'}</span>
              <svg className="svgFilters" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
            </div>
          </div>
        </div> : ""}
        
        <div className={showMobileForm ? 'mobileForm tw-top-0 tw-left-0 tw-fixed tw-bg-white tw-w-full tw-h-full tw-z-[999] tw-p-3 ' : 'tw-hidden'}    >

          <div className="tw-absolute tw-left-0 tw-top-0 tw-w-full shadow-sm tw-p-3 mobile-search-form-header">
            <>
              <button type="button" className="btn-close" onClick={() => toogleMobileSearchForm(false)} aria-label="Close"></button>  
            </>
          </div>

          <div className="tw-mt-[50px] tw-mb-[50px]">
            <div className={view + "  tw-relative "}>
              <div className="serch-form-field tw-bg-white location" >
к
                <LocationMobile
                  onToggle={() => handleAccordionChange(1)}
                  isOpen={openSection === 1}
                  pageID={pageID}
                  clearField={clearChildFiled}
                  locationList={locations}
                  changeLocation={transferLocation}
                  enableLocationFilter={enableLocationFilter}
                  settings={settings}
                />
              </div>
              <div className="serch-form-field tw-bg-white dates tw-relative tw-z-20">
                <DatePickerMobile
                  isOpen={openSection === 2}
                  onToggle={() => handleAccordionChange(2)}
                  clearField={clearChildFiled}
                  changeDates={transferDates}
                  enableDateFilter={enableDateFilter}
                  onChangeFlexibility={transferFlexibility}
                  enableFlexibleNightsFilter={enableFlexibleNightsFilter}
                />
              </div>
              {enableGuestFilter &&
                <div className="serch-form-field tw-bg-white guests tw-relative tw-z-10">
                  <GuestsMobile
                    isOpen={openSection === 3}
                    onToggle={() => handleAccordionChange(3)}
                    clearField={clearChildFiled}
                    changeAdults={transferAdults}
                    changeChildren={transferChildren}
                    changeInfants={transferInfants}
                    enableGuestFilter={enableGuestFilter}
                  />
                </div>
              }

              <div className="serch-form-field tw-bg-white filters">
                <FiltersMobile
                  changePriceMode={transferPriceMode}
                  changeAttrsOnLoad={transferAttrsOnLoad}
                  changeAttrsOnValueChange={transferAttrsOnChange}
                  startSearchFromModal={startSearch}
                  attributesList={attributes}
                  enabledFilters={isFilters}
                  enablePriceRangeSlider={enablePriceRangeSlider}
                  priceRangeSliderSettings={priceRangeSliderSettings}
                  changeNightRange={transNightRange}
                  changeWeekRange={transWeekRange}
                  clearField={clearChildFiled}

                />
              </div>

            </div>
          </div>

          <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-full shadow tw-p-3 mobile-search-form-footer tw-flex tw-justify-between">
            <button type="button" className="btn btn-link tw-text-black" onClick={clearAllData}>Clear All</button>
            <button type="button" className="btn btn-primary text-white" onClick={startMobileSearch}>Search</button>
          </div>
        </div>
      </>
    );
  }

  if (width > window.lg){
    return (
      <>
        {isValidForm && 
          <>
          {loadingForm ?
            <div className="loading tw-sticky tw-top-0 tw-bg-white search-from-wrapper">
              <div className={view + " search-from tw-relative "}>
                <div className="serch-form-field tw-bg-white location">
                  <Location 
                  locationList={locations} 
                  changeLocation={transferLocation} 
                  enableLocationFilter={enableLocationFilter} 
                  settings={settings}
                  />
                </div>
                <div className="serch-form-field tw-bg-white dates">
                  <DatePicker 
                    changeDates={transferDates} 
                    enableDateFilter={enableDateFilter}
                    onChangeFlexibility={transferFlexibility}
                    enableFlexibleNightsFilter={enableFlexibleNightsFilter}
                  />
                </div>
                {enableGuestFilter &&
                  <div className="serch-form-field tw-bg-white guests">
                    <Guests changeAdults={transferAdults} changeChildren={transferChildren} changeInfants={transferInfants} enableGuestFilter={enableGuestFilter} settings={settings} />
                  </div>
                }
                

                <div className="serch-form-field tw-bg-white filters">
                  <Filters
                    changePriceMode={transferPriceMode}
                    changeAttrsOnLoad={transferAttrsOnLoad}
                    changeAttrsOnValueChange={transferAttrsOnChange}
                    startSearchFromModal={startSearch}
                    attributesList={attributes}
                    enabledFilters={isFilters}
                    enablePriceRangeSlider={enablePriceRangeSlider}
                    priceRangeSliderSettings={priceRangeSliderSettings}
                    changeNightRange={transNightRange}
                    changeWeekRange={transWeekRange}
                    
                  />
                </div>
                <div className="serch-form-field tw-bg-white search-btn">
                  <button className="search-form-btn field tw-bg-primary tw-text-white tw-min-w-[120px]" onClick={() => { if (!isLoading) { startSearch(); } }} disabled={isLoading}>

                    <span >Search</span>
                    {isLoading ? 
                      <Spinner animation="grow" size="sm" className="tw-ml-4 tw-relative tw-top-[2px] " variant="white" >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner> : 
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        style={{ color: '#fff' }}  
                        size='lg'
                        className='tw-ml-4 tw-relative tw-top-[2px] '
                      />

                      }
                  </button>
                </div>
              </div>
            </div> : <SkeletonSearchForm />
          }
          </>
        }
      </>
    );
  }

};

 

export default SearchForm;
