import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './DatePicker.module.css';
import "react-dates/initialize";
import { DayPickerRangeController } from 'react-dates';
import "./_datepicker.scss";
import moment from 'moment';
import useGetUrlParams from 'utils/useGetUrlParams';
import useWindowSize from "utils/useWindowSize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusMinus } from '@fortawesome/pro-regular-svg-icons';

const DatePickerMobile = ({ enableFlexibleNightsFilter, onToggle, changeDates, enableDateFilter, clearField, isOpen, onChangeFlexibility }) => {

    const [selectedValue, setSelectedValue] = useState('option1');
    const location = useLocation();
    const { width } = useWindowSize();
    const [numberOfMonthsInCalendar, setOfMonthsInCalendar] = useState(2);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [showDatePicker, setShowDatePicker] = useState(isOpen);
    let [checkIn] = useGetUrlParams('CheckIn');
    let [checkOut] = useGetUrlParams('CheckOut');


    const handleDatesChange = ({ startDate, endDate }) => {
        // update temporary states
        setTempStartDate(startDate);
        setTempEndDate(endDate);
        // update the actual start and end dates
        setStartDate(startDate);
        setEndDate(endDate);
 
        
        // Notify the parent component if needed
        changeDates([startDate, endDate]);

        if (startDate && endDate) {
            setFocusedInput('endDate');
        }

        if (startDate && !endDate) {
            setFocusedInput('endDate');
        }

    };
 


 

    useEffect(() => {
        if (checkIn && checkOut) {
            let startDate = moment(checkIn, ["DD/MM/YYYY", "YYYY-MM-DD"]).isValid() ? moment(checkIn, ["DD/MM/YYYY", "YYYY-MM-DD"]) : null;
            let endDate = moment(checkOut, ["DD/MM/YYYY", "YYYY-MM-DD"]).isValid() ? moment(checkOut, ["DD/MM/YYYY", "YYYY-MM-DD"]) : null;

            setStartDate(startDate);
            setEndDate(endDate);
            changeDates([startDate, endDate]);
        }

        if (checkIn === '' && checkOut === '') {
            changeDates([null, null]);
        }
    }, [checkIn, checkOut]);


    useEffect(() => {
        if (width < window.lg) {
            setOfMonthsInCalendar(1);
        }
    }, [width]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const flexibleNights = searchParams.get('FlexibleNights') || searchParams.get('flexibleNights');
        if (flexibleNights && flexibleNights !== '') {
            if (flexibleNights === 'Exact') {
                setSelectedValue('option1');
                onChangeFlexibility('Exact');
            }
            if (flexibleNights === '1') {
                setSelectedValue('option2');
                onChangeFlexibility('1');
            }
            if (flexibleNights === '2') {
                setSelectedValue('option3');
                onChangeFlexibility('2');
            }
            if (flexibleNights === '3') {
                setSelectedValue('option4');
                onChangeFlexibility('3');
            }
            if (flexibleNights === '7') {
                setSelectedValue('option5');
                onChangeFlexibility('7');
            }
        } else {
            setSelectedValue('option1');
            onChangeFlexibility('Exact');
        }

    }, []);

    const NavButton = ({ text, style }) => {
        return (
            <button style={{ ...style }}>
                {text}
            </button>
        );
    };

 
    const nextButtonStyle = {
        border: '1px solid #000',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '14px',
        dipslay: 'block',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        width: 'calc(100% - 20px)'
    }; 

    const renderCalendarInfo = () => (

        <div className="tw-w-full tw-bg-white tw-fixed tw-bottom-0 tw-z-[999] tw-shadow tw-left-0 tw-border-t">
            <div className={styles.buttonGroup}>
                <label className={`${styles.button} ${selectedValue === 'option1' ? styles.selected : ''}`}>
                    <input
                        type="radio"
                        value="option1"
                        checked={selectedValue === 'option1'}
                        onChange={handleChangeFlexibility} // Updated function name here
                        className={styles.hiddenRadio}
                    />
                    Exact dates
                </label>
                <label className={`${styles.button} ${selectedValue === 'option2' ? styles.selected : ''}`}>
                    <input
                        type="radio"
                        value="option2"
                        checked={selectedValue === 'option2'}
                        onChange={handleChangeFlexibility} // And here
                        className={styles.hiddenRadio}
                    />
                    <FontAwesomeIcon
                        icon={faPlusMinus}
                        style={{ color: selectedValue === 'option2' ? '#000' : '#ccc' }} // Conditional color
                        size='xs'
                        className='tw-mr-1'
                    />
                    1 day
                </label>
                <label className={`${styles.button} ${selectedValue === 'option3' ? styles.selected : ''}`}>
                    <input
                        type="radio"
                        value="option3"
                        checked={selectedValue === 'option3'}
                        onChange={handleChangeFlexibility} // And here
                        className={styles.hiddenRadio}
                    />
                    <FontAwesomeIcon
                        icon={faPlusMinus}
                        style={{ color: selectedValue === 'option3' ? '#000' : '#ccc' }} // Conditional color
                        size='xs'
                        className='tw-mr-1'
                    />
                    2 days
                </label>
                <label className={`${styles.button} ${selectedValue === 'option4' ? styles.selected : ''}`}>
                    <input
                        type="radio"
                        value="option4"
                        checked={selectedValue === 'option4'}
                        onChange={handleChangeFlexibility}
                        className={styles.hiddenRadio}
                    />
                    <FontAwesomeIcon
                        icon={faPlusMinus}
                        style={{ color: selectedValue === 'option4' ? '#000' : '#ccc' }} // Conditional color
                        size='xs'
                        className='tw-mr-1'
                    />
                    3 days
                </label>
                <label className={`${styles.button} ${selectedValue === 'option5' ? styles.selected : ''}`}>
                    <input
                        type="radio"
                        value="option5"
                        checked={selectedValue === 'option5'}
                        onChange={handleChangeFlexibility}
                        className={styles.hiddenRadio}
                    />
                    <FontAwesomeIcon
                        icon={faPlusMinus}
                        style={{ color: selectedValue === 'option5' ? '#000' : '#ccc' }} // Conditional color
                        size='xs'
                        className='tw-mr-1'
                    />
                    7 days
                </label>
            </div>
            <div className="">



                <div className='tw-flex tw-justify-between tw-items-center tw-p-5 '>
                    <div className="">
                        <b>{getDuration()}</b>
                        <div
                            onClick={(e) => {
                                handleClear();
                            }}
                            className="tw-text-blue-500 tw-hover:text-blue-800 tw-cursor-pointer tw-font-bold">
                            Clear Dates
                        </div>
                    </div>

                    <div onClick={onToggle} className='tw-border tw-px-8 tw-py-3 tw-rounded tw-font-bold tw-border-black'>Next</div>
                </div>
            </div>
        </div>
    );

    const getDuration = () => {
        if (startDate && endDate) {
            const duration = endDate.diff(startDate, 'days');
            const formattedStartDate = startDate.format('MMM D');
            const formattedEndDate = endDate.format('MMM D');
            return `${formattedStartDate} - ${formattedEndDate} (${duration} nights)`;
        }
        return '';
    };


    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setTempStartDate(null); // Clear temporary start date
        setTempEndDate(null);   // Clear temporary end date
        setFocusedInput('startDate');
    };

    useEffect(() => {
        if (clearField > 0) {
            handleClear();
        }
    }, [clearField]);

    const handleChangeFlexibility = (event) => {
        setSelectedValue(event.target.value);
        const val = event.target.value;
        if (val === 'option1') {
            onChangeFlexibility('Exact');
        }
        if (val === 'option2') {
            onChangeFlexibility('1');
        }
        if (val === 'option3') {
            onChangeFlexibility('2');
        }
        if (val === 'option4') {
            onChangeFlexibility('3');
        }
        if (val === 'option5') {
            onChangeFlexibility('7');
        }

    };


    return (
        <>
             
            {enableDateFilter && (
                <div className={`${styles.DatePicker} ${isOpen ? "tw-border " : ""}`}>
                    <div className={!isOpen ? "tw-border tw-p-5" : ""} onClick={onToggle}>
                        <h5 className={isOpen ? " tw-p-5" : "tw-m-0"}>When
                            <span className={`tw-text-sm tw-ml-5`}>
                                {
                                    moment(startDate).isValid() && moment(endDate).isValid() ? `${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}` :
                                    moment(startDate).isValid() ? `From ${moment(startDate).format('DD MMM YYYY')}` :
                                    moment(endDate).isValid() ? `Until ${moment(endDate).format('DD MMM YYYY')}` : 'Anytime'
                                }
                            </span>
                        </h5>
                    </div>

                    {isOpen && (
                        <div className="date-picker-container date-picker-container-mobile date-picker-container-mobile-search" style={{ height: '50vh' }}>
                            <DayPickerRangeController
                                startDatePlaceholderText={'Check In'}
                                endDatePlaceholderText={'Check Out'}
                                startDate={tempStartDate}
                                endDate={tempEndDate}
                                customArrowIcon={" "}
                                showClearDates={true}
                                firstDayOfWeek={1}
                                displayFormat={'DD MMM YYYY'}
                                hideKeyboardShortcutsPanel={true}
                                orientation="verticalScrollable"
                                numberOfMonths={6}
                                onDatesChange={handleDatesChange}
                                focusedInput={focusedInput}
                                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                navPrev={' '}
                                navNext={<NavButton text="Load more dates" style={nextButtonStyle} />}
                                renderCalendarInfo={enableFlexibleNightsFilter ? renderCalendarInfo : null}
                                noBorder={true}
                            />
                        </div>
                    )}
                </div>
            )}
        </>

    );
};



export default DatePickerMobile;
