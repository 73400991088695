import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Rates = ({ rates, isLoading }) => {
    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Rates</h4>
                <Skeleton count={10} height={15} />
            </div>
        );
    }

    console.log(rates);

    const columns = [
        'StartDate', 
        'EndDate', 
        'Nightly', 
        'MinStay', 
        //'Occupancy',
        // 'Weekly', 
        //'Monthly', 
        //'Weekendly', 
        'ArrivalDay', 
        //'Departure'
    ];

    const columnHeaders = {
        StartDate: 'Start Date',
        EndDate: 'End Date',
        Nightly: 'Nightly Rate',
        MinStay: 'Minimum Stay',
        //Occupancy: 'Occupancy'
        // Weekly: 'Weekly Rate',
        // Monthly: 'Monthly Rate',
        // Weekendly: 'Weekend Rate',
         ArrivalDay: 'Arrival Day',
        // Departure: 'Departure Day'
    };

    return (
        <div>
            <h4>Rates</h4>
            <div className="table-responsive">
                <table className="tw-w-full tw-table-auto tw-border tw-border-gray-300 tw-mt-2">
                    <thead>
                        <tr className="tw-bg-gray-100">
                            {columns.map(column => (
                                <th key={column} className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">{columnHeaders[column]}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map((rate, index) => (
                            <tr key={index} className={index % 2 === 1 ? 'tw-bg-gray-50' : ''}>
                                {columns.map(column => (
                                    <td key={column} className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        {rate[column] && rate[column] !== '' ? rate[column] : 'N/A'}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {rates && rates.length === 0 && <div>No rates available</div>}
            </div>
        </div>
    );
};

export default Rates;
