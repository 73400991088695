import React, { useEffect } from 'react';
import './GoogleTranslateSwitcher.css';

const GoogleTranslateSwitcher = () => {
  useEffect(() => {
    // Function to create and append the Google Translate script
    const loadGoogleTranslateScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    // Callback function for Google Translate initialization
    const googleTranslateElementInit = () => {
      const defaultLang = getDefaultLanguage();
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en', includedLanguages: 'en,de,fr,pt,es,it,nl' },
        'google_translate_element'
      );

      const changeLanguage = (lang) => {
        const select = document.querySelector("#google_translate_element select");
        const customSelect = document.querySelector("#custom_translate_select");
        if (select && customSelect) {
          for (let i = 0; i < select.options.length; i++) {
            if (select.options[i].value.substr(0, 2) === lang) {
              select.selectedIndex = i;
              customSelect.value = lang;
              const event = new Event('change');
              select.dispatchEvent(event);
              break;
            }
          }
        }
      };

      // Delay to ensure the translate element is fully initialized before changing the language
      setTimeout(() => changeLanguage(defaultLang), 500);
    };

    // Load the Google Translate script and assign the callback
    loadGoogleTranslateScript().then(() => {
      window.googleTranslateElementInit = googleTranslateElementInit;
    }).catch((error) => {
      console.error('Failed to load Google Translate script:', error);
    });

    // Cleanup function to remove the script and callback
    return () => {
      const script = document.querySelector('script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]');
      if (script) {
        document.body.removeChild(script);
      }
      delete window.googleTranslateElementInit;
    };
  }, []);

  const getDefaultLanguage = () => {
    const url = window.location.href;
    if (url.includes('.de') || url.includes('/de')) {
      return 'de';
    }
    return 'en';
  };

  const changeLanguage = (event) => {
    const lang = event.target.value;
    const select = document.querySelector("#google_translate_element select");
    if (select) {
      for (let i = 0; i < select.options.length; i++) {
        if (select.options[i].value.substr(0, 2) === lang) {
          select.selectedIndex = i;
          const event = new Event('change');
          select.dispatchEvent(event);
          break;
        }
      }
    }
  };

  return (
    <div>
      <div id="google_translate_element" />
      <select id="custom_translate_select" onChange={changeLanguage} className="focus:tw-outline-none form-select">
        <option value="en">English</option>
        <option value="de">German</option>
        <option value="fr">French</option>
        <option value="pt">Portuguese</option>
        <option value="es">Spanish</option>
        <option value="it">Italian</option>
        <option value="nl">Dutch</option>
      </select>
    </div>
  );
};

export default GoogleTranslateSwitcher;
