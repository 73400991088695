import React, { useEffect, useState, useContext } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './AvailabilityCalendar.scss';
import useWindowSize from "utils/useWindowSize";
import CalendarDataContext from '../../../contexts/CalendarDataContext';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Skeleton from 'react-loading-skeleton';
import { parseISO, isBefore } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { css, StyleSheet } from 'aphrodite';
import _ from 'lodash';
const momentRange = extendMoment(moment);

 


const AvailabilityCalendar = ({ selectedDates, onDateChange, isLoading, disableAvailability }) => {
    const CalendarData = useContext(CalendarDataContext);
    const windowSize = useWindowSize();
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
 
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [calendarCols, setCalendarCols] = useState(3);
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
 
    const styles = StyleSheet.create({
        day: {
            ':hover': {
                backgroundColor: 'gray'
            },
        },
        dayBlocked: {
            ':hover': {
                backgroundColor: 'inherit',
            },
        },
    });

    useEffect(() => {
        if (CalendarData && CalendarData.length > 0) {
            setLoading(false);
        }
    }, [CalendarData]);

    useEffect(() => {
        if (windowSize.width > 1500) {
            setCalendarCols(3);
        }
        if (windowSize.width < 1500 && windowSize.width > 800) {
            setCalendarCols(2);
        }
        if (windowSize.width < 800) {
            setCalendarCols(1);
        }
    }, [windowSize.width]);

    const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
        if (disableAvailability) {
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onDateChange(newStartDate, newEndDate);
            setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
            return;
        }

        let selectedBookedDateInRange = hasSelectedBookedDateInRange(newStartDate || newEndDate);
        if (selectedBookedDateInRange) {
            setStartDate(null);
            setEndDate(null);
            setFocusedInput('startDate');
            return;
        }

        if (startDate && newStartDate && endDate && newEndDate) {
            if (newStartDate.isAfter(startDate) && newStartDate.isBefore(endDate)){
                setStartDate(newStartDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
            if (newStartDate.isBefore(startDate)){
                setStartDate(newStartDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
            if (newEndDate.isAfter(endDate)){
                setStartDate(newEndDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
        }


        setStartDate(newStartDate);
        setEndDate(newEndDate);
        onDateChange(newStartDate, newEndDate);
        setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
    };

  
    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
        if (startDate && endDate) {
            setReset(true);
        }
    }
 
    // Add this new function to get the minStay for a given date
    const getMinStayForDate = (date) => {
        const dateString = date ? date.format('DD/MM/YYYY') : null;
        const dateObject = dateString ? CalendarData.find(({ t }) => t.startsWith(dateString)) : null;

        // Add a check for undefined or null values
        return dateObject && dateObject.ms ? dateObject.ms : '1+';
    };

    const handleClearDates = () => {
        setStartDate(null);
        setEndDate(null);
        setFocusedInput('startDate');
    };

    const hasSelectedBookedDateInRange = (day) => {
        let date = startDate || day;
        let minStay = getMinStayForDate(date);
        
        let potentialEndDate = addMinStayToStartDate(date, minStay);
   
        const satisfiesCondition = !hasBookedDateInRange(date, potentialEndDate);
        return !satisfiesCondition;
    }

    const calendarDataMap = new Map(CalendarData.map(item => {
        const dateKey = item.t.split(" ")[0];
        return [dateKey, item];
    }));

    const isBlocked = day => {
        const dayInUTC = day.clone(); // Clone the day instead of converting to UTC

        const startDateInUTC = startDate ? startDate.clone() : null; // Clone the startDate if it exists
        const dateString = dayInUTC.format('DD/MM/YYYY');
        const currentDateObject = calendarDataMap.get(dateString);

        // Block past dates if disableAvailability is true
        if (disableAvailability) {
            if (day < new Date()) {
                return true;
            }
            return false;
        }

        // Block unavailable dates
        if (currentDateObject && !currentDateObject.e) {
            return true;
        }

        // Block dates before the selected start date
        if (startDateInUTC && dayInUTC.isBefore(startDateInUTC, 'day') && !endDate) {
            return true;
        }

        // Allow dates within the valid range when a start date is selected
        if (startDateInUTC && !endDate) {
            const minStayNumber = currentDateObject?.ms ? parseInt(currentDateObject.ms.replace('+', ''), 10) : 1;
            const potentialEndDate = startDateInUTC.clone().add(minStayNumber - 1, 'days');

            if (dayInUTC.isSameOrAfter(startDateInUTC, 'day') && dayInUTC.isSameOrBefore(potentialEndDate, 'day')) {
                return false; // Allow dates within the valid min stay range
            }
        }

        // Allow dates if they satisfy special booking conditions
        if (currentDateObject) {
            if (currentDateObject.c || currentDateObject.cs || currentDateObject.ce || currentDateObject.sb || currentDateObject.sbs || currentDateObject.sbe) {
                return false;
            }
        }

        // Block dates if no special condition is met and they are unavailable
        if (!currentDateObject || !currentDateObject.e) {
            return true;
        }

        // Allow dates that are changeover or special booking days
        return !(currentDateObject.cs || currentDateObject.sbs);
    };

 
    const hasBookedDateInRange = _.memoize((startDate, endDates) => {
        if (!startDate || !endDates) {
            // If either date is null, we cannot create a range
            return false;
        }

        // Ensure endDates is always an array
        if (!Array.isArray(endDates)) {
            endDates = [endDates];
        }

        // We only check against the first endDate
        const endDate = endDates[0];
        const range = startDate.isBefore(endDate, 'day') ? momentRange.range(startDate, endDate) : momentRange.range(endDate, startDate);
        return Array.from(range.by('days')).some(dateInRange => {
            const dateInRangeString = dateInRange.format('DD/MM/YYYY');
            const dateInRangeObject = CalendarData.find(({ t }) => t.startsWith(dateInRangeString));
            return dateInRangeObject ? !dateInRangeObject.e : false;
        });
    });

    const isWithinMinStay = (minStay, nightDifference) => {
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return false;
        }
   
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return nightDifference + 1 >= minStayNumber; // Include the start date
            } else {
                const availableStays = minStay.split(',').map(Number);
                return availableStays.includes(nightDifference + 1); // Include the start date
            }
        }
        
        else if (typeof minStay === 'number') { // Check if it's a single number
            
            return nightDifference + 1 === minStay; // Include the start date
        }
        console.error('Invalid minStay value: ', minStay);
        return true;
    };

    const addMinStayToStartDate = (startDate, minStay) => {
        
        const date = moment(startDate);
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return null;
        }
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return date.clone().add(minStayNumber, 'days');
            } else if (minStay.includes(',')) {
                const minStayNumbers = minStay.split(',').map(Number);
                return minStayNumbers.map(num => date.clone().add(num, 'days'));
            }
        } else if (typeof minStay === 'number') {
            return date.clone().add(minStay, 'days');
        }

        console.error('Invalid minStay value: ', minStay);
        return null;
    };
 
 

    const renderDayContents = day => {
        if (disableAvailability) {
            return (
                <>
                    {day.format('D')}
                </>
            );
        }

        let classNames = '';
        const dateString = day.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));
        let tooltipText = '';
        const selectedBookedDateInRange = hasSelectedBookedDateInRange();

        const isDateWithinRange = (date, start, end) => date.isSameOrAfter(start) && date.isSameOrBefore(end);

        const findBookedRanges = () => {
            return CalendarData
                .filter(({ e }) => !e) // Only consider booked dates
                .map(({ t }) => moment(t, 'DD/MM/YYYY'));
        };

        const checkValidEndDate = (startDate, endDate, minStay) => {
            if (!startDate || !endDate || !minStay) {
                return false;
            }

            // Calculate the minimum valid end date
            const minValidEndDate = startDate.clone().add(minStay, 'days');
            const bookedRanges = findBookedRanges();

            // Check if the end date is before the minimum valid end date
            if (endDate.isBefore(minValidEndDate)) {
                return true; // This date would show the min stay message
            }

            // Check if the end date overlaps with any booked ranges
            for (const bookedDate of bookedRanges) {
                if (isDateWithinRange(bookedDate, startDate, endDate)) {
                    return true; // This date would show the min stay message
                }
            }

            return false; // No overlap, valid date
        };

        const checkPotentialStartDate = (day, minStayNumber) => {
            const bookedRanges = findBookedRanges();
            const potentialEndDate = day.clone().add(minStayNumber, 'days');

            for (const bookedDate of bookedRanges) {
                if (isDateWithinRange(bookedDate, day, potentialEndDate)) {
                    return true; // This day overlaps with a booked date considering the min stay
                }
            }

            return false; // Valid start date
        };

        if (currentDateObject) {
            const minStayNumber = currentDateObject?.ms ? parseInt(currentDateObject.ms.replace('+', ''), 10) : null;

            if (selectedBookedDateInRange) {
                classNames += ' no-hover';
            }

            // If no dates are selected, and the potential start day overlaps with a booked range considering min stay, show a message but don't block
            if (!startDate && minStayNumber && checkPotentialStartDate(day, minStayNumber)) {
                tooltipText = `${minStayNumber} nights minimum`;
                setShowTooltip(true);
            }

            if (startDate && endDate) {
                // Reset all days when both start and end dates are selected
                classNames = '';
                tooltipText = '';
            } else if (startDate && !endDate) {
                // Block all previous days when start day is selected
                if (day.isBefore(startDate, 'day')) {
                    classNames += ' tw-cursor-not-allowed tw-text-gray-400';
                    tooltipText = 'Select a date after the start date';
                } else if (minStayNumber) {
                    const isValidEndDate = checkValidEndDate(startDate, day, minStayNumber);
                    console.log('isValidEndDate:', isValidEndDate, startDate, day, minStayNumber);

                    // Show the tooltip only if this is not the valid end date
                    if (isValidEndDate && (!currentDateObject.e || !currentDateObject.cs)) {
                        tooltipText = `${minStayNumber} nights minimum`;
                        setShowTooltip(true);
                    }
                }

                // Block days after the first booking period when start date is selected
                const bookedRanges = findBookedRanges();
                const firstBookedDateAfterStart = bookedRanges.find(bookedDate => bookedDate.isAfter(startDate));

                if (firstBookedDateAfterStart && day.isAfter(firstBookedDateAfterStart, 'day')) {
                    classNames += ' tw-cursor-not-allowed tw-text-gray-400';
                    tooltipText = 'Select a date before the next booked range';
                }
            }

            if (!currentDateObject.e) {
                tooltipText = 'Booked';
                classNames += ' booked tw-bg-gray-300 tw-text-white tw-cursor-not-allowed';
            }

            // Ensure that the start day and valid subsequent days are selectable
            if (startDate && minStayNumber && !endDate) {
                const potentialEndDate = startDate.clone().add(minStayNumber, 'days');

                if (day.isSameOrAfter(startDate, 'day') && day.isSameOrBefore(potentialEndDate, 'day')) {
                    classNames = ''; // Remove blocking classes
                    const isValidEndDate = checkValidEndDate(startDate, day, minStayNumber);
                    // Show tooltip for min stay only if the day is not a valid end day (available date)
                    if (!isValidEndDate && !currentDateObject.e || !currentDateObject.cs) {
                        //tooltipText = `${minStayNumber} nights minimum 2`;
                    } else {
                        tooltipText = ''; // Hide tooltip for valid end date
                    }
                }
            }
        }

        return (
            <div className={`${classNames} tw-relative tw-flex tw-h-full tw-justify-center tw-items-center`}>
                {day.format('D')}
                {(showTooltip && tooltipText && tooltipText.length > 0) &&
                    <div className='calendar_tooltipText'
                        style={{
                            position: 'absolute',
                            left: `${cursorPos.x - 0}px`,
                            top: `${cursorPos.y - 30}px`,
                            zIndex: 1000,
                        }}
                    >
                        {tooltipText}
                    </div>
                }
            </div>
        );
    };



    if (isLoading) {
        return (
            <div>
                <h4>Availability Calendar</h4>
                <Skeleton height={400} />
            </div>
        );
    }

    const renderCalendarInfo = () => (
        <div className="tw-flex tw-mb-2 tw-justify-center">
            <div className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-primary"></div>
                <span>Selected</span>
            </div>

            <div className="tw-flex tw-items-center tw-ml-4">
                <div className="tw-w-4 tw-h-4 tw-mr-2 tw-bg-gray-300"></div>
                <span>Booked</span>
            </div>

        </div>
    );
    
    return loading ? (
        <div>Loading...</div>
    ) : (
            <div className={`${styles.availabilityCalendar} availabilityCalendar`}>

                <h4 className="tw-text-xl tw-mb-4">Availability Calendar</h4>
                <button
                    className="tw-mb-4 tw-bg-primary tw-text-white tw-font-bold tw-py-1 tw-px-4 tw-rounded"
                    onClick={handleClearDates}
                >
                    Clear Dates
                </button>
                <DayPickerRangeController
 
                    startDate={startDate}
                    endDate={endDate}
                    daySize={34}
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    numberOfMonths={calendarCols}
                    isOutsideRange={isBlocked}

                    maxDate={false}
                    showClearDates
                    block
                    firstDayOfWeek={1}
                    keepOpenOnDateSelect
                    renderCalendarInfo={() => <div />}
                    calendarInfoPosition="top"
                    hideKeyboardShortcutsPanel
                    renderDayContents={renderDayContents}
                    noBorder
                    transitionDuration={90}
                />
                {renderCalendarInfo()}
            </div>
    );

 
};

export default AvailabilityCalendar;

 