import { useEffect, useState, useContext } from 'react';
import SkeletonContent from './SkeletonContent/SkeletonContent';
import Slider from './Slider/Slider';
import SearchForm from '../../components/SearchForm/SearchForm';
import { SiteSettingsContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import useWindowSize from "utils/useWindowSize";
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import WidgetsList from 'components/WidgetsList/WidgetsList';
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';
 
 
const ContentPage = ({ pageId, keyID }) => {
    const [isFetching, setFetching] = useState(false);
    const siteSettings = useContext(SiteSettingsContext);
    const { width } = useWindowSize();
    let [response, setResponse] = useState(null);
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [widgets, setWidgets] = useState([]);
    const [mapIsShwon, setMapVisability] = useState(true);
    const [propertyList, setPropertyList] = useState(null);
    const [googleMapAPIKey, setGoogleMapAPIKey] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [sort, setSort] = useState('');
    let [total, setTotal] = useState('');
    const [dictionary, setDictionary] = useState(null);
 
    const [displayMode, setDisplayMode] = useState("map");
    const [settings, setSettings] = useState(null); 
   

    useEffect(() => {
        if (mapIsShwon === false) {
            setDisplayMode('fullWidth');
        }
        if (mapIsShwon === true) {
            setDisplayMode('map');
        }
    }, [mapIsShwon, displayMode]);

    useEffect(() => {
        setGoogleMapAPIKey(siteSettings.SiteSettings.GoogleMapAPIKey);
        if ( width < window.lg){
            setMapVisability(false);
        }
    }, []);

    const getContent = (sortDirection, Sort, Show, Page, clear) =>{
        setFetching(true);
        if (pageId) {
            fetch(`${process.env.REACT_APP_BASE_URL}apis/contentpage?pageid=${pageId}&clearcache=${clear}&SortDirection=${sortDirection}&Sort=${Sort}&Currency=${currency}&Show=${Show}&Page=${Page}`)
                .then(response => response.json())
                .then(data => {
                    setResponse(data);
                    setDictionary(data.Settings.Dictionaries);
                    setPropertyList(data.Content.PropertiesList);
                    setSettings(data.Settings.SearchFormSettings);
                   
                    if (data.Content.PropertyAutoSelectorhWidget){
                        setTotal(data.Content.PropertyAutoSelectorhWidget.Total);
                    }
                    setFetching(false);
                    setWidgets(data.Content.Widgets);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        getContent();
    }, [pageId, currency])

    if (!response){
        return (
            <SkeletonContent />
        );
    }

    function upMapState(val) {
        setMapVisability(!val);
    }

    function changeSorting(sort, order) {
        getContent(order, sort);
    }

    const updateResults = (e) => {
        getContent(sortDirection, sort, e.Show, e.Page, e.clearcache);
    }

 
    if ( !response.Content.PropertiesList && !response.Content.SearchWidget){
       // window.location = "/search/";
    }


 
   
    return (
        <>   

        
            {response.Content.SearchWidget &&
                <Slider url={response.Content.SearchWidget.Images[0].Url} title={response.Content.SearchWidget.Title} subtitle={response.Content.SearchWidget.SubTitle} >
                    <div className="tw-px-3 lg:tw-bg-[#fff] tw-mt-10">
                        <SearchForm pageID={pageId} keyID={keyID} Currency={siteSettings.Currency} />
                    </div>
                </Slider>
            }

            <WidgetsList 
                Widgets={widgets} 
                pageId={pageId} 
                keyID={keyID} 
                settings={settings} 
                googleMapAPIKey={googleMapAPIKey} 

            />

            <div className="tw-p-3">
 
                {propertyList ? <div>
                    <div className='tw-grid tw-grid-cols-5 tw-gap-3'>
                        {mapIsShwon ? <div className="tw-col-span-3 lg:tw-col-span-3">
                            <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
                            <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} isFetching={isFetching} dictionary={dictionary} total={total} sendParams={updateResults} />
                        </div> :
                            <div className="tw-col-span-5 order-lg-2">
                                <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
                                {propertyList && <PropertiesList propertyList={propertyList} displayMode={mapIsShwon} dictionary={dictionary} total={total} sendParams={updateResults}  />}
                            </div>
                        }
                        {
                            (mapIsShwon && width > window.lg) ? <div className="lg:tw-columns-1 tw-col-span-2 ">
                                <div className="tw-sticky tw-top-0">
                                    <div className="">
                                        {propertyList &&
                                            <Wrapper apiKey={googleMapAPIKey}  >
                                                <SearchResultMap mapData={propertyList} />
                                            </Wrapper>
                                        }
                                    </div>
                                </div>
                            </div> : null
                        }
                        {(mapIsShwon && width < window.lg) ?
                            <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
                                <div className="map">
                                    <div className="">
                                        {propertyList &&
                                            <Wrapper apiKey={googleMapAPIKey}  >
                                                <SearchResultMap mapData={propertyList} />
                                            </Wrapper>
                                        }
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div> : ''}

            </div>

        </>
    );
};



export default ContentPage;
