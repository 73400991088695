import React, { useState, createContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
const SiteSettingsContext = createContext({});
const TemplateTypeContext = createContext('');
const PageIdContext = createContext('');
const GetSiteDataContext = createContext(() => { });
const CurrencyContext = createContext('');
const TemplateContext = createContext('');
const SortContext = createContext('');
const SEOContext = createContext();

function SiteSettingsProvider({ children }) {
    const [seoData, setSeoData] = useState({
        title: '',
        description: '',
        keywords: ''
    });
    const [siteSettings, setSiteSettings] = useState(null);
    const [templateType, setTemplateType] = useState('');
    const [pageId, setPageId] = useState('');
    const [currency, setCurrency] = useState('');
    const [template, setTemplate] = useState('');
    const [sort, setSort] = useState('');
    const [order, setOrder] = useState('');
    const [lang, setLang] = useState('en');
    const [error, setError] = useState(null);

    const value = {
        sort,
        setSort,
        order,
        setOrder
    };

    const updateSeo = (data) => {
        setSeoData({
            title: data.SeoTitle,
            description: data.MetaDescription,
            keywords: data.MetaKeywords
        });
    };

    const getSiteData = () => {
        let urlLocation = window.location.pathname;
        fetch(`${process.env.REACT_APP_BASE_URL}apis/wp?u=${urlLocation}`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
                const pageId = data.Id;
                setPageId(pageId);
                setTemplateType(data.Type);
                setTemplate(data.Template);
                setLang(data?.Lang);

                const cachedSiteSettings = JSON.parse(sessionStorage.getItem(`siteSettings_${pageId}`) || sessionStorage.getItem(`sitesettings`));

                if (cachedSiteSettings) {
                    setSiteSettings(cachedSiteSettings);
                } else {
                    fetch(`${process.env.REACT_APP_BASE_URL}apis/sitesettings?pageid=${pageId}&clearcache=true`)
                        .then(response => response.json())
                        .then(data => {
                            if (data.error) {
                                throw new Error(data.error);
                            }
                            setSiteSettings(data);
                            sessionStorage.setItem(`siteSettings_${pageId}`, JSON.stringify(data));
                        })
                        .catch(error => {
                            console.error('Error fetching site settings:', error);
                            setError('Error fetching site settings: ' + error.message);
                        });
                }
            })
            .catch(error => {
                console.error('Error fetching page data:', error);
                setError('Error fetching page data: ' + error.message);
            });
    };


    useEffect(() => {
        getSiteData();
    }, []);

    const customCSS = siteSettings && siteSettings.HeaderSettings && siteSettings.HeaderSettings.CustomCSSWithinSite
        ? siteSettings.HeaderSettings.CustomCSSWithinSite
        : '';

    return (
        <>
            <CustomStyleComponent css={customCSS} />
            <SiteSettingsContext.Provider value={siteSettings}>
                <SEOContext.Provider value={{ seoData, updateSeo }}>
                    <TemplateTypeContext.Provider value={templateType}>
                        <TemplateContext.Provider value={template}>
                            <TemplateContext.Provider value={lang}>
                                <PageIdContext.Provider value={pageId}>
                                    <CurrencyContext.Provider value={[currency, setCurrency]}>
                                        <GetSiteDataContext.Provider value={getSiteData}>
                                            <SortContext.Provider value={value}>
                                                <Helmet>
                                                    <title>{seoData.title}</title>
                                                    <meta name="description" content={seoData.description} />
                                                    {seoData.keywords && <meta name="keywords" content={seoData.keywords} />}
                                                </Helmet>
                                                {error ? <div>{error}</div> : children}
                                            </SortContext.Provider>
                                        </GetSiteDataContext.Provider>
                                    </CurrencyContext.Provider>
                                </PageIdContext.Provider>
                            </TemplateContext.Provider>
                        </TemplateContext.Provider>
                    </TemplateTypeContext.Provider>
                </SEOContext.Provider>
            </SiteSettingsContext.Provider>
        </>
    );
}

function CustomStyleComponent({ css }) {
    return <style dangerouslySetInnerHTML={{ __html: css }} />
}

export { SiteSettingsContext, TemplateContext, TemplateTypeContext, SiteSettingsProvider, PageIdContext, GetSiteDataContext, CurrencyContext, SortContext };
