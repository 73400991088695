import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Navigation.scss';
import Skeleton from "react-loading-skeleton";
import { Outlet, Link } from "react-router-dom";
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { CurrencySelector } from 'components/CurrencySelector/CurrencySelector';
import { PropertyInputSearch } from 'components/PropertyInputSearch/PropertyInputSearch';
import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { NavigationMegaMenu } from 'components/NavigationMegaMenu/NavigationMegaMenu';
import GoogleTranslateSwitcher from 'components/GoogleTranslateSwitcher/GoogleTranslateSwitcher';
import { BrowserRouter } from 'react-router-dom';
import useWindowSize from "utils/useWindowSize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from "@fortawesome/pro-regular-svg-icons";


const Navigation = (settings) => {
  const { width } = useWindowSize();
  const { height } = useWindowSize();

  let [isLoading, setIsLoading] = useState(true);

  let [enablenavigation, setEnablenavigation] = useState('');
  let [enableDropdown, setEnableDropdown] = useState();
  let [navigationDepth, setNavigationDepth] = useState();
  let [showHome, setShowHome] = useState();
  let [centerNav, setCenterNav] = useState();

  let [megaMenu, setMegaMenu] = useState();
  let [enableNavigationLogo, setEnableNavigationLogo] = useState();
  let [showWishlist, setShowWishlist] = useState();
  let [showWishlistUrl, setShowWishlistUrl] = useState();
  let [showWishlistName, setShowWishlistName] = useState();
  let [showguestLogin, setShowGuestLogin] = useState();
  let [guestLoginUrlName, setGuestLoginUrlName] = useState();
  let [guestLoginUrl, setGuestLoginUrl] = useState();

  let [showPhoneNumberNavigation, setShowPhoneNumberNavigation] = useState();

  let [menuNodes, setMenuNodes] = useState([]);

  let [showCurrencySelector, setShowCurrencySelector] = useState();
  let [showLanguageSwitcher, setShowLanguageSwitcher] = useState();
  let [showShowGoogleTranslate, setShowGoogleTranslate] = useState();
  let [showEnableSearch, setEnableSearch] = useState();

  let [callUsDictionary, setCallUsDictionary] = useState();

  let [currency, setCurrency] = useState();
  let [currecncies, setCurrencies] = useState();
  let [contactTelephone, setContactTelephone] = useState();
  let [desktopLogo, setDesktopLogo] = useState();
  let [desktopLogoHeight, setDesktopLogoHeight] = useState();
  let [desktopLogoWidth, setDesktopLogoWidth] = useState();
  let [mobileLogo, setMobileLogo] = useState();
  let [mobileLogoHeight, setMobileLogoHeight] = useState();
  let [mobileLogoWidth, setMobileLogoWidth] = useState();

  let [headerColorClass, setHeaderColorClass] = useState('');

  let [languages, setLanguages] = useState('');
  let [language, setLanguage] = useState('');
  let [navOpen, setNavOpen] = useState(false);

  useEffect(() => {

    if (settings.navigationSettings.CenterNavigationBar) {
      setCenterNav('center');
    } else {
      setCenterNav('end');
    }

    setContactTelephone(settings.siteSettings.ContactTelephone);
    setCallUsDictionary(settings.navigationSettings.CallUsDictionary);

    setEnableDropdown(settings.navigationSettings.EnableDropdowns);
    setMegaMenu(settings.navigationSettings.EnableMegaMenu);
    setEnableNavigationLogo(settings.navigationSettings.EnableNavigationLogo);
    setEnableSearch(settings.navigationSettings.Enablesearch);
    setShowGuestLogin(settings.navigationSettings.GuestLoginNavigation);
    setGuestLoginUrlName(settings.navigationSettings.GuestLoginUrlName);
    setGuestLoginUrl(settings.navigationSettings.GurestLoginUrl);
    setMenuNodes(settings.navigationSettings.MenuNodes);

    setNavigationDepth(settings.navigationSettings.NavigationDepth);
    setShowPhoneNumberNavigation(settings.navigationSettings.PhoneNumberNavigation);
    setShowCurrencySelector(settings.navigationSettings.ShowHideCurrencySelector);
    setShowHome(settings.navigationSettings.ShowHomeInTheNavigation);
    setShowLanguageSwitcher(settings.navigationSettings.LanguageSwitcher);
    setShowWishlist(settings.navigationSettings.WishlistNavigation);
    setShowWishlistUrl(settings.navigationSettings.WishlistUrl);
    setShowWishlistName(settings.navigationSettings.WishlistUrlName);

    setCurrencies(settings.currencies);

    setCurrency(settings.currency);

    setDesktopLogo(settings.siteSettings.DesktopLogo);
    setDesktopLogoHeight(settings.siteSettings.DesktopLogoHeight);
    setDesktopLogoWidth(settings.siteSettings.DesktopLogoWidth);
    setMobileLogo(settings.siteSettings.MobileLogo);
    setMobileLogoHeight(settings.siteSettings.MobileLogoHeight);
    setMobileLogoWidth(settings.siteSettings.MobileLogoWidth);
    setShowGoogleTranslate(settings.navigationSettings.ShowGoogleTranslate);


    setLanguages(settings.languages);
    setLanguage(settings.language);

    if (settings.bodyClasses.includes("navigation-dark")) {
      setHeaderColorClass('navigation-dark tw-bg-primary text-white');
    }

    if (settings.bodyClasses.includes("navigation-light") || settings.bodyClasses.includes("navigation-light") === undefined) {
      setHeaderColorClass('navigation-light text-black');
    }

  }, [settings]);

  useEffect(() => {

    let nav = document.getElementsByTagName('body')[0].getAttribute('nav');

    if (nav === "1") {
      setEnablenavigation(true);
      return;
    }
    setEnablenavigation(false);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1600);
    } else {
      setIsLoading(false);
    }
  }, []);

  function expandNav() {
    setNavOpen(!navOpen);
     
    if (navOpen) {
      document.body.classList.remove('mobileMenuOpen');
    } else {
      document.body.classList.add('mobileMenuOpen');
    }
  }

  const closeMenu = () => {
    setNavOpen(false);
    document.body.classList.remove('mobileMenuOpen');
  }



  if (!enablenavigation) {
    return;
  }

  return (
    <>

      {!isLoading ?
        <div className={`${headerColorClass} Navigation tw-p-3 tw-relative xl:tw-flex xl:tw-items-center`}>
          <div className="tw-flex tw-justify-between lg:tw-justify-center tw-items-center">  
            {enableNavigationLogo && <div className="navigation-logo lg:tw-inline tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
              {desktopLogo !== null ?
                <a href="/">
                  <img
                    src={desktopLogo}
                    className="img-responsive tw-hidden md:tw-inline desktop-logo tw-max-w-xl tw-max-h-24 tw-w-full tw-h-auto"
                    alt="Desktop Logo"
                  />
                </a>
                : null}
              {mobileLogo !== null ?
                <a href="/">
                  <img
                    src={mobileLogo}
                    className="img-responsive mobile-logo tw-w-full tw-max-w-12 tw-h-auto tw-max-h-12 md:tw-hidden"
                    alt="Mobile Logo"
                  />
                </a>
                : null
              }




            </div>

            }

            <div className='tw-flex tw-items-center'>


              {showWishlist && width < 1024 &&
                <a href={showWishlistUrl} className="tw-no-underline" target="_blank" rel="noopener">
                  <FontAwesomeIcon 
                    icon={faBookmark} 
                    style={{ 
                      color: 'black',
                      fontSize: '1.8rem',
                      marginRight: '1rem'
                    }}
                  />
                </a>
              }
              {width < 1024 ? <div className="tw-space-y-2" onClick={expandNav}>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
              </div> : null}
            </div>
          </div>
 
          
          <div className="tw-grid md:tw-flex md:tw-justify-center xl:tw-justify-stretch  lg:tw-items-stretch tw-gap-3  ">
            <NavigationMenu menuNodes={menuNodes} theme={settings.bodyClasses} open={navOpen} closeMenu={closeMenu} navigationDepth={navigationDepth} showWishlist={showWishlist} />
 
            <div className="service-menu lg:tw-flex lg:tw-justify-end lg:tw-items-center lg:tw-gap-3">
              <div className=" tw-flex tw-justify-between lg:tw-justify-end lg:tw-items-center lg:tw-gap-3 tw-items-center">
                {showPhoneNumberNavigation &&
                  <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                    {callUsDictionary + " "}
                    <span className="site-phone-text">{contactTelephone}</span>
                  </a>
                }

                {showguestLogin &&
                  <a href={guestLoginUrl} className="tw-no-underline" target="_blank" rel="noopener">

                    <span className="guestLoginUrl tw-font-semibold">{guestLoginUrlName}</span>
                  </a>
                }

                {showWishlist && width >= 1024 &&
                  <a href={showWishlistUrl} className="tw-no-underline" target="_blank" rel="noopener">

                    <span className="showWishlistName tw-font-semibold">{showWishlistName}</span>
                  </a>
                }

                {showLanguageSwitcher && <LanguageSwitcher languages={languages} language={language} />}

                {showCurrencySelector && <CurrencySelector currecncies={currecncies} />}
              </div>

              {showEnableSearch && <PropertyInputSearch />}

              {width >= 1024  && <GoogleTranslateSwitcher /> }
            </div>

          </div>






        </div> :

        <div className={"tw-py-3  "}>
          <div className="container-fluid " >
            <div className={`${headerColorClass} Navigation tw-p-3 tw-relative lg:tw-grid lg:tw-grid-cols-2 xl:tw-flex tw-justify-${centerNav}  xl:tw-items-center`}>

              {
                <div className="tw-hidden lg:tw-grid 2xl:tw-flex lg:tw-justify-stretch lg:tw-items-stretch tw-gap-3 tw-bg-primary tw-h-[40px]" >
                  <div className="tw-block tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between tw-gap-3">

                  </div>

                </div>
              }
            </div>
          </div >
        </div >


      }


      <Outlet />
    </>
  );
};

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;