import React, {useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Lazy, EffectFade } from "swiper";
import WishButton from 'components/SearchResult/WishButton/WishButton';
import PropertyPrice from 'components/SearchResult/PropertyPrice/PropertyPrice';
import PropertyRating from 'components/SearchResult/PropertyRating/PropertyRating';
import SpecialOffer from 'components/SearchResult/SpecialOffer/SpecialOffer';
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { faBath } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PropertyItem = ({ item, index, linkParams='', enableSlider, dictionary }) => { 
    const refProperties = useRef([]); 
 
    if (!item ){
        return;
    }
    const uniqueKey = `${item?.Id}-${item.PropertyName || index}-${new Date().getTime()}`;
    return(
        <>  {item && 
            <div key={uniqueKey} ref={element => (refProperties.current[item.Id] = element)} className='propertyItem overflow-hidden  tw-relative shadow rounded '>
                <div className='tw-relative   '>
                    <PropertyRating ratingNumber={item.ReviewsRating} />
                    <WishButton size="2xl" heartColor="#f23b79" className='tw-absolute tw-z-1 tw-right-5 tw-top-5 tw-cursor-pointer' id={item.Id} />
                    <SpecialOffer item={item} />
                    {enableSlider ? <a href={item.MoreDetailsURL + linkParams} target="_blank" rel="noreferrer">
                        <Swiper
                            effect="fade"
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={true}
                            lazy={{ loadPrevNext: false, loadPrevNextAmount: 3 }}
                            navigation={true} modules={[EffectFade, Navigation, Pagination, Lazy]}
                        >
                            {
                                item.PropertyImagesUrls.map((image, index) => {
                                    if (index < 8) {
                                        
                                        return <SwiperSlide key={`${item.Id}-${index}`} className="tw-relative">
                                            <img src={'https://' + item.PropertyImagesUrls[0]}  data-src={'https://' + image} alt='img' className='swiper-lazy tw-w-full' />
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                        </SwiperSlide>
                                    }
                                }
                                )
                            }
                        </Swiper>
                    </a> :
                        <a href={item.MoreDetailsURL + linkParams} target="_blank" rel="noreferrer">
                            <img src={'https://' + item.PropertyImagesUrls[0]} alt='img' className='swiper-lazy tw-w-full' />
                        </a>
                    }

                </div>
                <div className="tw-p-2">
                    <div className="tw-font-bold tw-text-[1.2rem]"><PropertyPrice item={item} dictionary={dictionary} /></div>
                    <h5 className="tw-text-primary tw-m-0 ">{item.PropertyName}</h5>
                    <div className="">{item.Location}</div>
                    <div className="search-result-attribute-list ">
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Sleeps} </span>
                            <FontAwesomeIcon icon={faPerson} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Bedrooms} </span>
                            <FontAwesomeIcon icon={faBed} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        <div className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">
                            <span className="tw-py-2">{item.Bathrooms} </span>
                            <FontAwesomeIcon icon={faBath} color="#183153" className="tw-right-5 tw-top-5  " />
                        </div>
                        {item.AllPropertyAttributes && item.AllPropertyAttributes.map((attr, index) => {
                            if (attr.Icon !== null) {
                                return (
                                    <div key={index} className="attribute-item tw-text-[1.5rem] tw-mr-5 tw-inline-flex tw-items-center tw-gap-1">

                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {attr.AttrName}
                                                </Tooltip>
                                            }
                                        >
                                            <img src={attr.Icon} alt={attr.TypeName} className="tw-right-5 tw-top-5 tw-inline" width={'24'} height={'24'} />
                                        </OverlayTrigger>
                                    </div>
                                );
                            }
                        })}
      
                    </div>
                </div>
            </div>
        }

        </>
    );
};

export default PropertyItem;